export interface FifaRanking {
  rank: number;
  point: number;
  country: string;
}

export const fifaRankings: FifaRanking[] = [
  {
    rank: 1,
    point: 1828.45,
    country: 'Belgium',
  },
  {
    rank: 2,
    point: 1823.42,
    country: 'Brazil',
  },
  {
    rank: 3,
    point: 1786.15,
    country: 'France',
  },
  {
    rank: 4,
    point: 1766.99,
    country: 'Argentina',
  },
  {
    rank: 5,
    point: 1755.52,
    country: 'England',
  },
  {
    rank: 6,
    point: 1740.77,
    country: 'Italy',
  },
  {
    rank: 7,
    point: 1704.75,
    country: 'Spain',
  },
  {
    rank: 8,
    point: 1660.25,
    country: 'Portugal',
  },
  {
    rank: 9,
    point: 1654.54,
    country: 'Denmark',
  },
  {
    rank: 10,
    point: 1653.73,
    country: 'Netherlands',
  },
  {
    rank: 11,
    point: 1648.33,
    country: 'Germany',
  },
  {
    rank: 12,
    point: 1647.9,
    country: 'Mexico',
  },
  {
    rank: 13,
    point: 1643.34,
    country: 'USA',
  },
  {
    rank: 14,
    point: 1642.83,
    country: 'Switzerland',
  },
  {
    rank: 15,
    point: 1620.74,
    country: 'Croatia',
  },
  {
    rank: 16,
    point: 1614.05,
    country: 'Uruguay',
  },
  {
    rank: 17,
    point: 1588.26,
    country: 'Sweden',
  },
  {
    rank: 18,
    point: 1587.78,
    country: 'Senegal',
  },
  {
    rank: 19,
    point: 1585.89,
    country: 'Colombia',
  },
  {
    rank: 20,
    point: 1578.01,
    country: 'Wales',
  },
  {
    rank: 21,
    point: 1572.89,
    country: 'IR Iran',
  },
  {
    rank: 22,
    point: 1563.45,
    country: 'Peru',
  },
  {
    rank: 23,
    point: 1549.82,
    country: 'Japan',
  },
  {
    rank: 24,
    point: 1547.42,
    country: 'Morocco',
  },
  {
    rank: 25,
    point: 1547.38,
    country: 'Serbia',
  },
  {
    rank: 26,
    point: 1543.16,
    country: 'Chile',
  },
  {
    rank: 27,
    point: 1535.08,
    country: 'Ukraine',
  },
  {
    rank: 28,
    point: 1530.62,
    country: 'Poland',
  },
  {
    rank: 29,
    point: 1522.85,
    country: 'Korea Republic',
  },
  {
    rank: 30,
    point: 1511.56,
    country: 'Austria',
  },
  {
    rank: 31,
    point: 1510.42,
    country: 'Czech Republic',
  },
  {
    rank: 32,
    point: 1509.91,
    country: 'Nigeria',
  },
  {
    rank: 33,
    point: 1497.82,
    country: 'Canada',
  },
  {
    rank: 34,
    point: 1497.05,
    country: 'Egypt',
  },
  {
    rank: 35,
    point: 1493.42,
    country: 'Russia',
  },
  {
    rank: 36,
    point: 1489.92,
    country: 'Tunisia',
  },
  {
    rank: 37,
    point: 1486.86,
    country: 'Australia',
  },
  {
    rank: 38,
    point: 1480.82,
    country: 'Cameroon',
  },
  {
    rank: 39,
    point: 1472.72,
    country: 'Turkey',
  },
  {
    rank: 40,
    point: 1471.82,
    country: 'Scotland',
  },
  {
    rank: 41,
    point: 1465.62,
    country: 'Hungary',
  },
  {
    rank: 42,
    point: 1464.06,
    country: 'Costa Rica',
  },
  {
    rank: 43,
    point: 1460.93,
    country: 'Algeria',
  },
  {
    rank: 44,
    point: 1458.63,
    country: 'Ecuador',
  },
  {
    rank: 45,
    point: 1455.43,
    country: 'Norway',
  },
  {
    rank: 46,
    point: 1455.36,
    country: 'Slovakia',
  },
  {
    rank: 47,
    point: 1453.18,
    country: 'Romania',
  },
  {
    rank: 48,
    point: 1446.49,
    country: 'Mali',
  },
  {
    rank: 49,
    point: 1444.29,
    country: 'Republic of Ireland',
  },
  {
    rank: 50,
    point: 1440.53,
    country: 'Paraguay',
  },
  {
    rank: 51,
    point: 1439.62,
    country: "Côte d'Ivoire",
  },
  {
    rank: 52,
    point: 1437.91,
    country: 'Qatar',
  },
  {
    rank: 53,
    point: 1433.95,
    country: 'Saudi Arabia',
  },
  {
    rank: 54,
    point: 1424.97,
    country: 'Northern Ireland',
  },
  {
    rank: 55,
    point: 1421.99,
    country: 'Greece',
  },
  {
    rank: 56,
    point: 1418.58,
    country: 'Burkina Faso',
  },
  {
    rank: 57,
    point: 1411.77,
    country: 'Finland',
  },
  {
    rank: 58,
    point: 1411.45,
    country: 'Venezuela',
  },
  {
    rank: 59,
    point: 1391.19,
    country: 'Bosnia and Herzegovina',
  },
  {
    rank: 60,
    point: 1382.81,
    country: 'Iceland',
  },
  {
    rank: 61,
    point: 1381.45,
    country: 'Ghana',
  },
  {
    rank: 62,
    point: 1378.62,
    country: 'Jamaica',
  },
  {
    rank: 63,
    point: 1375.56,
    country: 'Panama',
  },
  {
    rank: 64,
    point: 1375.42,
    country: 'Slovenia',
  },
  {
    rank: 65,
    point: 1374.98,
    country: 'Albania',
  },
  {
    rank: 66,
    point: 1369.53,
    country: 'Congo DR',
  },
  {
    rank: 67,
    point: 1367.23,
    country: 'FYR Macedonia',
  },
  {
    rank: 68,
    point: 1358.24,
    country: 'South Africa',
  },
  {
    rank: 69,
    point: 1353.1,
    country: 'United Arab Emirates',
  },
  {
    rank: 70,
    point: 1346.04,
    country: 'El Salvador',
  },
  {
    rank: 71,
    point: 1345.47,
    country: 'Bulgaria',
  },
  {
    rank: 72,
    point: 1342.89,
    country: 'Montenegro',
  },
  {
    rank: 73,
    point: 1336.88,
    country: 'Cape Verde Islands',
  },
  {
    rank: 74,
    point: 1325.44,
    country: 'Iraq',
  },
  {
    rank: 75,
    point: 1313.81,
    country: 'China PR',
  },
  {
    rank: 76,
    point: 1308.12,
    country: 'Bolivia',
  },
  {
    rank: 77,
    point: 1306.7,
    country: 'Israel',
  },
  {
    rank: 78,
    point: 1303.96,
    country: 'Honduras',
  },
  {
    rank: 79,
    point: 1301,
    country: 'Oman',
  },
  {
    rank: 80,
    point: 1298.39,
    country: 'Curaçao',
  },
  {
    rank: 81,
    point: 1292.58,
    country: 'Guinea',
  },
  {
    rank: 82,
    point: 1290.65,
    country: 'Gabon',
  },
  {
    rank: 83,
    point: 1282.53,
    country: 'Benin',
  },
  {
    rank: 84,
    point: 1279.18,
    country: 'Uganda',
  },
  {
    rank: 85,
    point: 1277.02,
    country: 'Uzbekistan',
  },
  {
    rank: 86,
    point: 1269.24,
    country: 'Georgia',
  },
  {
    rank: 87,
    point: 1268.05,
    country: 'Haiti',
  },
  {
    rank: 88,
    point: 1264.64,
    country: 'Zambia',
  },
  {
    rank: 89,
    point: 1263.61,
    country: 'Bahrain',
  },
  {
    rank: 90,
    point: 1259.84,
    country: 'Jordan',
  },
  {
    rank: 91,
    point: 1251.22,
    country: 'Syria',
  },
  {
    rank: 92,
    point: 1242.24,
    country: 'Armenia',
  },
  {
    rank: 93,
    point: 1236.45,
    country: 'Luxembourg',
  },
  {
    rank: 94,
    point: 1233.43,
    country: 'Belarus',
  },
  {
    rank: 95,
    point: 1228.97,
    country: 'Lebanon',
  },
  {
    rank: 96,
    point: 1228.69,
    country: 'Kyrgyz Republic',
  },
  {
    rank: 97,
    point: 1221.4,
    country: 'Congo',
  },
  {
    rank: 98,
    point: 1218.55,
    country: 'Vietnam',
  },
  {
    rank: 99,
    point: 1210.07,
    country: 'Equatorial Guinea',
  },
  {
    rank: 100,
    point: 1208.9,
    country: 'Palestine',
  },
  {
    rank: 101,
    point: 1205.99,
    country: 'Trinidad and Tobago',
  },
  {
    rank: 102,
    point: 1205.61,
    country: 'Madagascar',
  },
  {
    rank: 103,
    point: 1202.26,
    country: 'Kenya',
  },
  {
    rank: 104,
    point: 1182.75,
    country: 'India',
  },
  {
    rank: 105,
    point: 1178.65,
    country: 'Cyprus',
  },
  {
    rank: 106,
    point: 1176.5,
    country: 'Estonia',
  },
  {
    rank: 107,
    point: 1173.81,
    country: 'Sierra Leone',
  },
  {
    rank: 108,
    point: 1169.96,
    country: 'Korea DPR',
  },
  {
    rank: 109,
    point: 1163.05,
    country: 'Kosovo',
  },
  {
    rank: 110,
    point: 1162.93,
    country: 'Namibia',
  },
  {
    rank: 111,
    point: 1161.66,
    country: 'New Zealand',
  },
  {
    rank: 112,
    point: 1160.14,
    country: 'Thailand',
  },
  {
    rank: 113,
    point: 1158.62,
    country: 'Guinea-Bissau',
  },
  {
    rank: 114,
    point: 1158.39,
    country: 'Niger',
  },
  {
    rank: 115,
    point: 1152.56,
    country: 'Tajikistan',
  },
  {
    rank: 116,
    point: 1152.52,
    country: 'Mauritania',
  },
  {
    rank: 117,
    point: 1151,
    country: 'Mozambique',
  },
  {
    rank: 118,
    point: 1149.51,
    country: 'Libya',
  },
  {
    rank: 119,
    point: 1145.08,
    country: 'Malawi',
  },
  {
    rank: 120,
    point: 1140.71,
    country: 'Kazakhstan',
  },
  {
    rank: 121,
    point: 1139.02,
    country: 'Azerbaijan',
  },
  {
    rank: 122,
    point: 1138.56,
    country: 'Zimbabwe',
  },
  {
    rank: 123,
    point: 1138.4,
    country: 'Guatemala',
  },
  {
    rank: 124,
    point: 1136.99,
    country: 'Faroe Islands',
  },
  {
    rank: 125,
    point: 1135.18,
    country: 'Gambia',
  },
  {
    rank: 126,
    point: 1133.24,
    country: 'Togo',
  },
  {
    rank: 127,
    point: 1131.72,
    country: 'Angola',
  },
  {
    rank: 128,
    point: 1131.07,
    country: 'Antigua and Barbuda',
  },
  {
    rank: 129,
    point: 1129.91,
    country: 'Philippines',
  },
  {
    rank: 130,
    point: 1127.2,
    country: 'Central African Republic',
  },
  {
    rank: 131,
    point: 1126.2,
    country: 'Comoros',
  },
  {
    rank: 132,
    point: 1118.76,
    country: 'Tanzania',
  },
  {
    rank: 133,
    point: 1118.31,
    country: 'Sudan',
  },
  {
    rank: 134,
    point: 1117.6,
    country: 'Turkmenistan',
  },
  {
    rank: 135,
    point: 1100.03,
    country: 'Latvia',
  },
  {
    rank: 136,
    point: 1097.16,
    country: 'Rwanda',
  },
  {
    rank: 137,
    point: 1091.77,
    country: 'Lithuania',
  },
  {
    rank: 138,
    point: 1081.24,
    country: 'Ethiopia',
  },
  {
    rank: 139,
    point: 1077.76,
    country: 'St Kitts and Nevis',
  },
  {
    rank: 140,
    point: 1077.57,
    country: 'Suriname',
  },
  {
    rank: 141,
    point: 1075.82,
    country: 'Burundi',
  },
  {
    rank: 142,
    point: 1072.78,
    country: 'Solomon Islands',
  },
  {
    rank: 143,
    point: 1065.56,
    country: 'Kuwait',
  },
  {
    rank: 144,
    point: 1062.21,
    country: 'Nicaragua',
  },
  {
    rank: 145,
    point: 1058.28,
    country: 'Liberia',
  },
  {
    rank: 146,
    point: 1057.62,
    country: 'Lesotho',
  },
  {
    rank: 147,
    point: 1054.14,
    country: 'Eswatini',
  },
  {
    rank: 148,
    point: 1053.39,
    country: 'Hong Kong',
  },
  {
    rank: 149,
    point: 1051.59,
    country: 'Botswana',
  },
  {
    rank: 150,
    point: 1049.77,
    country: 'Afghanistan',
  },
  {
    rank: 151,
    point: 1046.26,
    country: 'Yemen',
  },
  {
    rank: 152,
    point: 1044.56,
    country: 'Myanmar',
  },
  {
    rank: 153,
    point: 1035.12,
    country: 'New Caledonia',
  },
  {
    rank: 154,
    point: 1034.53,
    country: 'Malaysia',
  },
  {
    rank: 155,
    point: 1030.28,
    country: 'Andorra',
  },
  {
    rank: 156,
    point: 1029.42,
    country: 'Dominican Republic',
  },
  {
    rank: 157,
    point: 1021.58,
    country: 'Maldives',
  },
  {
    rank: 158,
    point: 1017.78,
    country: 'Chinese Taipei',
  },
  {
    rank: 159,
    point: 1014.27,
    country: 'Tahiti',
  },
  {
    rank: 160,
    point: 1001.61,
    country: 'Indonesia',
  },
  {
    rank: 161,
    point: 1000.78,
    country: 'Singapore',
  },
  {
    rank: 162,
    point: 996.27,
    country: 'Fiji',
  },
  {
    rank: 163,
    point: 995.94,
    country: 'Barbados',
  },
  {
    rank: 164,
    point: 995.62,
    country: 'Vanuatu',
  },
  {
    rank: 165,
    point: 990.55,
    country: 'Papua New Guinea',
  },
  {
    rank: 166,
    point: 982.43,
    country: 'Bermuda',
  },
  {
    rank: 167,
    point: 982.22,
    country: 'Nepal',
  },
  {
    rank: 168,
    point: 977.81,
    country: 'South Sudan',
  },
  {
    rank: 169,
    point: 974.03,
    country: 'Grenada',
  },
  {
    rank: 170,
    point: 967.42,
    country: 'Belize',
  },
  {
    rank: 171,
    point: 966.61,
    country: 'Cambodia',
  },
  {
    rank: 172,
    point: 962.77,
    country: 'Puerto Rico',
  },
  {
    rank: 173,
    point: 960.71,
    country: 'St Vincent and the Grenadines',
  },
  {
    rank: 174,
    point: 958.95,
    country: 'Malta',
  },
  {
    rank: 175,
    point: 958.87,
    country: 'Guyana',
  },
  {
    rank: 176,
    point: 955.18,
    country: 'Mauritius',
  },
  {
    rank: 177,
    point: 953.45,
    country: 'St Lucia',
  },
  {
    rank: 178,
    point: 950.71,
    country: 'Montserrat',
  },
  {
    rank: 179,
    point: 948.96,
    country: 'Cuba',
  },
  {
    rank: 180,
    point: 935,
    country: 'Chad',
  },
  {
    rank: 181,
    point: 926.85,
    country: 'Moldova',
  },
  {
    rank: 182,
    point: 922.1,
    country: 'Macau',
  },
  {
    rank: 183,
    point: 916.72,
    country: 'Dominica',
  },
  {
    rank: 184,
    point: 916.68,
    country: 'Mongolia',
  },
  {
    rank: 185,
    point: 910.96,
    country: 'Bhutan',
  },
  {
    rank: 186,
    point: 907.83,
    country: 'Bangladesh',
  },
  {
    rank: 187,
    point: 904.6,
    country: 'Laos',
  },
  {
    rank: 188,
    point: 903.9,
    country: 'Brunei Darussalam',
  },
  {
    rank: 189,
    point: 903.31,
    country: 'São Tomé and Príncipe',
  },
  {
    rank: 190,
    point: 900.27,
    country: 'American Samoa',
  },
  {
    rank: 191,
    point: 899.52,
    country: 'Liechtenstein',
  },
  {
    rank: 192,
    point: 896.62,
    country: 'Djibouti',
  },
  {
    rank: 193,
    point: 894.26,
    country: 'Samoa',
  },
  {
    rank: 194,
    point: 873.71,
    country: 'Somalia',
  },
  {
    rank: 195,
    point: 873.64,
    country: 'Cayman Islands',
  },
  {
    rank: 196,
    point: 870.63,
    country: 'Seychelles',
  },
  {
    rank: 197,
    point: 866.81,
    country: 'Pakistan',
  },
  {
    rank: 198,
    point: 865.47,
    country: 'Timor-Leste',
  },
  {
    rank: 199,
    point: 861.81,
    country: 'Tonga',
  },
  {
    rank: 200,
    point: 859.97,
    country: 'Aruba',
  },
  {
    rank: 201,
    point: 858.5,
    country: 'Bahamas',
  },
  {
    rank: 202,
    point: 855.56,
    country: 'Eritrea',
  },
  {
    rank: 203,
    point: 853.6,
    country: 'Gibraltar',
  },
  {
    rank: 204,
    point: 842.93,
    country: 'Sri Lanka',
  },
  {
    rank: 205,
    point: 839.84,
    country: 'Turks and Caicos Islands',
  },
  {
    rank: 206,
    point: 838.33,
    country: 'Guam',
  },
  {
    rank: 207,
    point: 816.13,
    country: 'US Virgin Islands',
  },
  {
    rank: 208,
    point: 812.94,
    country: 'British Virgin Islands',
  },
  {
    rank: 209,
    point: 792.34,
    country: 'Anguilla',
  },
  {
    rank: 210,
    point: 780.33,
    country: 'San Marino',
  },
];
