import React, { useCallback, useEffect, useMemo, useState } from 'react';

import { MDBDataTable } from 'mdbreact';

import Spinner from '../components/Spinner';
import MainLayout from '../layouts/MainLayout';
import { FootballResult } from '../types/apiFootball';
import { getMatchesBy2Teams, getPercenttagesPredicted, getRecentMatches, getScoreFromString, predictScoresBetweenTwoTeams } from '../utils/aiFootball';

function AiFootballPage() {
  const [fullHistory, setFullHistory] = useState<FootballResult[]>([]);
  const [recentHistory, setRecentHistory] = useState<FootballResult[]>([]);
  const [selectedDatas, setSelectedDatas] = useState<FootballResult[]>([]);
  const [countries, setCountries] = useState<string[]>([]);
  const [team1, setTeam1] = useState<string>();
  const [team2, setTeam2] = useState<string>();
  const [isPending, setIsPending] = useState<boolean>(true);
  const [hidden, setHidden] = useState<boolean>(true);
  const [goodPredictions, setGoodPredictions] = useState<number>(0);
  const [exactPredictions, setExactPredictions] = useState<number>(0);
  const [scoresBetween2Teams, setScoresBetween2Teams] = useState<{
    team1: number;
    team2: number;
  }>();

  useEffect(() => {
    const prediction = getPercenttagesPredicted(recentHistory);
    setGoodPredictions(prediction.goodPredictions);
    setExactPredictions(prediction.exactPredictions);
  }, [recentHistory]);

  useEffect(() => {
    setTeam1(countries[0]);
    setTeam2(countries[1]);
    setScoresBetween2Teams(predictScoresBetweenTwoTeams(countries[0], countries[1], recentHistory));
    setSelectedDatas(getMatchesBy2Teams(countries[0], countries[1], recentHistory));
  }, [recentHistory, countries]);

  useEffect(() => {
    fetch('https://raw.githubusercontent.com/martj42/international_results/master/results.csv')
      .then((response) => response.text())
      .then((text) => {
        const datas = text
          .split('\n')
          .map((row: any) => {
            const [date, homeTeam, awayTeam, homeScore, awayScore, tournament, city, country, neutral] = row.split(',');
            return {
              date: new Date(date),
              homeTeam,
              awayTeam,
              homeScore,
              awayScore,
              tournament,
              city,
              country,
              neutral,
              isPrediction: homeScore === 'NA' || awayScore === 'NA',
            };
          })
          .filter((data: FootballResult) => data.homeScore !== 'NA' && data.awayScore !== 'NA' && data.date.getFullYear() > 1900)
          .sort((a: FootballResult, b: FootballResult) => (a.date > b.date ? -1 : 1));

        const recentDatas = getRecentMatches(datas);

        const dataWithPrediction = datas.map((result: FootballResult) => {
          const prediction = result.isPrediction
            ? predictScoresBetweenTwoTeams(result.homeTeam, result.awayTeam, recentDatas)
            : { team1: getScoreFromString(result.homeScore), team2: getScoreFromString(result.awayScore) };

          return {
            ...result,
            homeScore: result.isPrediction ? prediction.team1.toString() : result.homeScore,
            awayScore: result.isPrediction ? prediction.team2.toString() : result.awayScore,
          } as FootballResult;
        });

        setFullHistory(dataWithPrediction);
        setRecentHistory(getRecentMatches(dataWithPrediction));

        const countries = datas
          .map((d: FootballResult) => d.country)
          .filter((c: string, i: number, a: string[]) => a.indexOf(c) === i)
          .sort();

        setCountries(countries);
      })
      .catch((error) => {
        console.error(error);
      })
      .finally(() => {
        setIsPending(false);
      });
  }, []);

  const getTable = useCallback((tableDatas: FootballResult[]) => {
    return {
      columns: [
        {
          label: 'Date',
          field: 'date',
          sort: 'asc',
        },
        {
          label: 'Home Team',
          field: 'homeTeam',
          sort: 'asc',
        },
        {
          label: 'Away Team',
          field: 'awayTeam',
          sort: 'asc',
        },
        {
          label: 'Home Score',
          field: 'homeScore',
          sort: 'asc',
        },
        {
          label: 'Away Score',
          field: 'awayScore',
          sort: 'asc',
        },
        {
          label: 'Tournament',
          field: 'tournament',
          sort: 'asc',
        },
        {
          label: 'City',
          field: 'city',
          sort: 'asc',
        },
        {
          label: 'Country',
          field: 'country',
          sort: 'asc',
        },
      ],
      rows: tableDatas.map((data) => {
        const date = data.date.toLocaleDateString('en-GB').split('/');
        return {
          date: date[2] + '/' + date[1] + '/' + date[0],
          homeTeam: data.homeTeam,
          awayTeam: data.awayTeam,
          homeScore: data.isPrediction ? <p className='text-blue-500 font-bold'>{data.homeScore}</p> : <p>{data.homeScore}</p>,
          awayScore: data.isPrediction ? <p className='text-blue-500 font-bold'>{data.awayScore}</p> : <p>{data.awayScore}</p>,
          tournament: data.tournament,
          city: data.city,
          country: data.country,
        };
      }),
    };
  }, []);

  const dataMBTable = useMemo(() => getTable(fullHistory), [fullHistory]);

  const selectedDataMBTable = useMemo(() => getTable(selectedDatas), [selectedDatas]);

  const handleTeam1Change = (event: React.ChangeEvent<{ value: unknown }>) => {
    const team = event.target.value as string;
    setTeam1(team);
    setScoresBetween2Teams(predictScoresBetweenTwoTeams(team, team2 || '', recentHistory));
    setSelectedDatas(getMatchesBy2Teams(team, team2 || '', fullHistory));
  };

  const handleTeam2Change = (event: React.ChangeEvent<{ value: unknown }>) => {
    const team = event.target.value as string;
    setTeam2(team);
    setScoresBetween2Teams(predictScoresBetweenTwoTeams(team1 || '', team, recentHistory));
    setSelectedDatas(getMatchesBy2Teams(team1 || '', team, fullHistory));
  };

  return (
    <MainLayout>
      <div className='flex flex-col justify-center items-start md:items-center space-y-20 mx-2 md:mx-5'>
        <div className='flex flex-col justify-center items-center'>
          <div className='mb-10 text-center'>
            <div className='text-2xl font-bold'>{/* World Cup 2022 Champion Prediction: <span className=' text-blue-500'>Brazil</span> */}</div>
            {/* <button onClick={() => setHidden(false)} className='text-blue-500'>
              click here for full details on all 2022 World Cup predictions
            </button>
            <PopUpWorldCupPrediction hidden={hidden} setHidden={setHidden} /> */}
          </div>
          <h1 className='text-2xl font-bold text-blue-500'>Custom Prediction</h1>
          {isPending ? (
            <Spinner />
          ) : (
            <div className='flex flex-col'>
              <div className='flex space-x-10 w-full items-center justify-center'>
                <div className='flex flex-col justify-center items-center'>
                  <label htmlFor='homeTeam' className='font-bold'>
                    Team 1
                  </label>
                  <select name='country' id='homeTeam' className='text-center font-bold' value={team1} onChange={handleTeam1Change}>
                    {countries.map((country, index) => (
                      <option key={index} value={country}>
                        {country}
                      </option>
                    ))}
                  </select>
                  <div className='px-4 py-1 border border-blue-500 rounded-lg w-full text-center font-bold text-blue-500'>{scoresBetween2Teams?.team1}</div>
                </div>

                <div className='flex flex-col justify-center items-center'>
                  <label htmlFor='awayTeam' className='font-bold text-sm'>
                    Team 2
                  </label>
                  <select name='country' id='awayTeam' className='text-center font-bold' value={team2} onChange={handleTeam2Change}>
                    {countries.map((country, index) => (
                      <option key={index} value={country}>
                        {country}
                      </option>
                    ))}
                  </select>
                  <div className='px-4 py-1 border border-blue-500 rounded-lg w-full text-center font-bold text-blue-500'>{scoresBetween2Teams?.team2}</div>
                </div>
              </div>
              <MDBDataTable className='text-center align-middle' autoWidth={true} entries={100} striped bordered sortable data={selectedDataMBTable} />
            </div>
          )}
        </div>

        <div className='flex flex-col justify-center items-center'>
          <h1 className='text-2xl font-bold text-center'>History and Upcoming Prediction</h1>
          {/* <div>Percentage of correct prediction: {goodPredictions}%</div> */}
          {/* <div>Percentage of correct score prediction: {exactPredictions}%</div> */}
          {isPending ? (
            <Spinner />
          ) : (
            <MDBDataTable className='text-center align-middle' autoWidth={true} entries={100} striped bordered sortable data={dataMBTable} />
          )}
        </div>
        <div className='w-full text-center'>
          <span className='font-bold'>Disclaimer:</span> <br />
          This application is an experimental research. <br />
          The prediction may not be correct and should not be used as a reference. <br />
          The app owner has no responsibility for the results.
        </div>
      </div>
    </MainLayout>
  );
}

export default AiFootballPage;
