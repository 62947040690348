import { fifaRankings } from '../constants/football/fifaRankings';
import {
  FootballResult,
  TeamStats,
} from '../types/apiFootball';

export const getMaxRankingPoints = (): number => {
  return 2000;
};
export const getMiddleRankingPoints = (): number => {
  return (fifaRankings[0].point + fifaRankings[fifaRankings.length - 1].point) / 2;
};

export const getRankingPoint = (teamName: string) => {
  return fifaRankings.find((team) => team.country === teamName)?.point ?? 1000;
};

export const getMatchImportance = (tournament: string) => {
  switch (tournament) {
    case 'FIFA World Cup':
      return 1.5;
    case 'UEFA Euro':
      return 1.3;
    case 'Copa América':
      return 1.3;
    case 'African Cup of Nations':
      return 1.2;
    case 'AFC Asian Cup':
      return 1;
    default:
      return 0.5;
  }
};

export const getMatchesByTeam = (team: string, results: FootballResult[]) => {
  return results.filter((result) => {
    return result.homeTeam === team || result.awayTeam === team;
  });
};

export const getMatchesBy2Teams = (team1: string, team2: string, results: FootballResult[]) => {
  return getMatchesByTeam(team1, getMatchesByTeam(team2, results));
};

export const getPointByMatchResult = (result: string) => {
  switch (result) {
    case 'W':
      return 5;
    case 'D':
      return 1;
    case 'L':
      return -2;
    default:
      return 0;
  }
};

export const getScoreFromString = (score: string) => {
  const scoreNumber = parseInt(score);
  if (isNaN(scoreNumber)) {
    return 0;
  }
  return scoreNumber;
};

export const getTeamStats = (team: string, results: FootballResult[]) => {
  const matches = getMatchesByTeam(team, results);

  if (matches === undefined || matches.length === 0)
    return {
      scores: 0,
      team,
      totalGames: 0,
    };

  const middleTime = (results[0]?.date?.getTime() + results[results?.length - 1]?.date?.getTime()) / 2;

  const stats = matches.reduce(
    (acc, match) => {
      const matchImportance = getMatchImportance(match.tournament);
      const homeScore = getScoreFromString(match.homeScore);
      const awayScore = getScoreFromString(match.awayScore);
      const time = Math.max(match.date.getTime() / middleTime, 1);

      let opponent = '';
      let score = 0;
      let matchResult = '';
      if (match.homeTeam === team) {
        score = homeScore;
        opponent = match.awayTeam;
        matchResult = homeScore > awayScore ? 'W' : homeScore === awayScore ? 'D' : 'L';
      } else {
        score = awayScore;
        opponent = match.homeTeam;
        matchResult = awayScore > homeScore ? 'W' : awayScore === homeScore ? 'D' : 'L';
      }

      const weightOponent = Math.max(getRankingPoint(opponent) / getRankingPoint(team), 1);

      return {
        scores: acc.scores + score * matchImportance * weightOponent * time,
        points: acc.points + getPointByMatchResult(matchResult) * matchImportance * weightOponent * time,
      };
    },
    {
      scores: 0,
      points: 0,
    }
  );

  return {
    ...stats,
    team,
    totalGames: matches.length,
  };
};

export const getTeamStatsBetweenTwoTeams = (team1: string, team2: string, results: FootballResult[]) => {
  const matches = getMatchesBy2Teams(team1, team2, results);

  const team1Stats = getTeamStats(team1, matches);
  const team2Stats = getTeamStats(team2, matches);

  return {
    team1: team1Stats,
    team2: team2Stats,
  };
};

export const getAvgScore = (stats: TeamStats) => {
  return (stats?.totalGames ?? 0) > 0 ? ((stats?.scores ?? 0) + (stats?.points ?? 0)) / (2 * (stats?.totalGames ?? 0) + 2) : 0;
};

export const predictScoresBetweenTwoTeams = (team1: string, team2: string, results: FootballResult[]) => {
  const team1Stats = getTeamStats(team1, results);
  const team2Stats = getTeamStats(team2, results);
  const statsBetween2Teams = getTeamStatsBetweenTwoTeams(team1, team2, results);

  const team1AvgScore = getAvgScore(team1Stats);
  const team1AvgScoreFaceOff = getAvgScore(statsBetween2Teams.team1);

  const team2AvgScore = getAvgScore(team2Stats);
  const team2AvgScoreFaceOff = getAvgScore(statsBetween2Teams.team2);

  const team1AvgScoreTotal = statsBetween2Teams.team1.totalGames > 0 ? (team1AvgScore + team1AvgScoreFaceOff) / 2 : team1AvgScore;
  const team2AvgScoreTotal = statsBetween2Teams.team2.totalGames > 0 ? (team2AvgScore + team2AvgScoreFaceOff) / 2 : team2AvgScore;

  return getScores(
    Math.max((team1AvgScoreTotal * getRankingPoint(team1)) / getMiddleRankingPoints(), 0),
    Math.max((team2AvgScoreTotal * getRankingPoint(team2)) / getMiddleRankingPoints(), 0)
  );
};

export const getScores = (score1: number, score2: number) => {
  let score1Rounded = Math.round(score1);
  let score2Rounded = Math.round(score2);

  if (score1Rounded === score2Rounded) {
    if (score1 - score2 > 0.5) score1Rounded += 1;
    if (score2 - score1 > 0.5) score2Rounded += 1;
  }

  return {
    team1: score1Rounded,
    team2: score2Rounded,
  };
};

export const getBonusScore = (scoreDiff: number) => {
  return scoreDiff % 1 > 0.5 ? 1 : 0;
};

export const getRecentMatches = (datas: FootballResult[]) => {
  const limitYear = 5;
  const lastestYear = datas[0]?.date?.getFullYear() ?? new Date().getFullYear();
  return datas.filter((data) => {
    return data.date.getTime() > new Date(`1/1/${lastestYear - limitYear}`).getTime();
  });
};

export const predictScoresBetweenTwoTeamsWithSupriseFactor = (team1: string, team2: string, results: FootballResult[]) => {
  return predictScoresBetweenTwoTeams(team1, team2, results);
};

export const getPercenttagesPredicted = (results: FootballResult[]) => {
  const selectedResults = results.slice(0, Math.floor(results.length / 2));
  const totalMatches = selectedResults.length;

  const totalGoodPredictions = selectedResults.reduce(
    (acc, result, currentIndex) => {
      const { team1: homeScorePredicted, team2: awayScorePredicted } = predictScoresBetweenTwoTeamsWithSupriseFactor(
        result.homeTeam,
        result.awayTeam,
        results.slice(currentIndex + 1)
      );

      const homeScore = getScoreFromString(result.homeScore);
      const awayScore = getScoreFromString(result.awayScore);

      const matchResult = homeScore > awayScore ? 'W' : homeScore === awayScore ? 'D' : 'L';
      const matchResultPredicted = homeScorePredicted > awayScorePredicted ? 'W' : homeScorePredicted === awayScorePredicted ? 'D' : 'L';

      return {
        goodPredictions: acc.goodPredictions + (matchResult === matchResultPredicted ? 1 : 0),
        exactPredictions: acc.exactPredictions + (homeScore === homeScorePredicted && awayScore === awayScorePredicted ? 1 : 0),
      };
    },
    {
      goodPredictions: 0,
      exactPredictions: 0,
    }
  );

  return {
    goodPredictions: Math.round((totalGoodPredictions.goodPredictions / totalMatches) * 100),
    exactPredictions: Math.round((totalGoodPredictions.exactPredictions / totalMatches) * 100),
  };
};
